.banks-modal-cont {
  margin-top: 40px !important;
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.add-bank-modal-body {
  min-width: 47% !important;
  max-width: 47% !important;
  min-height: 55vh !important;
  max-height: 55vh !important;
}

.banks-modal-where-bank-accounts-are {
  overflow-y: auto;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.a-bank-card-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  scrollbar-width: thin;
}

.bank-account-status-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  min-width: 180px;
}

.a-bank-card-cont-left,
.a-bank-card-cont-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  width: 60%;
  height: 100px;
}

.a-bank-card-cont-right {
  width: 40% !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
  height: 100px;
  overflow-y: auto;
}

.account-type-status-primary {
  background: #facecc;
  color: var(--background-color-dark, #a70103);
  border-radius: 15px;
  padding: 5px 20px 5px 20px;
  font-family: "Sora", sans-serif;
  font-size: small;
  text-align: left;
}

.account-type-status-normal {
  background: var(--Grey-for-color, #f5f5f5);
  color: rgb(35, 35, 35);
  border-radius: 15px;
  padding: 5px 15px 5px 15px;
  font-family: "Sora", sans-serif;
  font-family: "Sora", sans-serif;
  font-size: small;
  text-align: left;
}

@media screen and (max-width: 500px) {
  .add-bank-modal-body,
  .bank-modal-body {
    min-width: 90% !important;
    max-width: 90% !important;
    min-height: 85vh !important;
    max-height: 85vh !important;
  }

  .a-bank-card-cont {
    overflow: scroll;
  }

  .a-bank-card-cont-left {
    min-width: 200px !important;
  }

  .a-bank-card-cont-right {
    min-width: 200px !important;
  }
  .bl-sep {
    display: none;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
  .add-bank-modal-body,
  .bank-modal-body {
    min-width: 90% !important;
    max-width: 90% !important;
    min-height: 85vh !important;
    max-height: 85vh !important;
  }
}
