.dashboard-intro-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  background-image: url("/public/images/dd-banner.png");
  background-size: cover;
}

.dashboard-intro-container-main {
  width: 95%;
  height: 220px;
  box-shadow: 0px 4px 18.9px 0px #facecc;
  background: var(--background-color3, #ffffff);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
}

.dsh-intro-left {
  width: 55%;
  height: 220px;
}

.dsh-intro-right {
  width: 40%;
  height: 220px;
}

.ds-stat-container-row {
  margin-top: 40px;
}

.dashboard-extra-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.dashboard-extra-cont-left,
.dashboard-extra-cont-right {
  width: 44%;
  height: 250px;
  box-shadow: 1px 4px 12.3px 0px #00000040;
}

.dashboard-extra-cont-right {
  justify-content: center !important;
  gap: 47px;
}

@media screen and (max-width: 500px) {
  .ds-stat-container-row {
    gap: 20px;
  }

  .dashboard-extra-cont {
    flex-wrap: wrap !important;
  }

  .dashboard-extra-cont-left,
  .dashboard-extra-cont-right {
    min-width: 300px !important;
  }

  .dashboard-intro-container-main,
  .dashboard-intro-container {
    flex-wrap: wrap !important;
    height: fit-content !important;
  }

  .dashboard-intro-container-main {
    padding: 15px;
    gap: 10px !important;
    justify-content: center;
  }

  .dsh-intro-left {
    width: 300px;
    height: fit-content !important;
  }

  .dsh-intro-right {
    width: 300px;
    height: fit-content !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
  .ds-stat-container-row {
    gap: 20px;
  }

  .dashboard-extra-cont {
    flex-wrap: wrap !important;
  }

  .dashboard-extra-cont-left,
  .dashboard-extra-cont-right {
    min-width: 350px !important;
  }
}
