.profile-screen-cont {
  padding: 50px 70px 30px 70px;
}

.profile-docs-screen-cont {
  padding: 50px 40px 30px 40px;
}

.profile-screen-cont h3 {
  margin-bottom: 25px;
}

.profile-section-box {
  width: 100%;
  border: 0.4px solid var(--text-color-400, #0d171766);
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;
}

.profile-pic {
  width: 100px;
  border-radius: 40px;
}

.upload-profile-card {
  width: 100px;
  height: 100px;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.upload-profile-card input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.upload-profile-card .profile-pic {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  filter: brightness(0.7);
}

.upload-profile-card .profile-pic-annotation {
  position: absolute;
  width: 50px;
}

.profile-container-section-header {
  border-bottom: 0.4px solid var(--text-color-400, #0d171766);
  margin: 40px 35px 40px 35px;
  padding-bottom: 17px;
  font-weight: bold;
}

.profile-info-box {
  gap: 30px;
  display: flex;
  flex-direction: column;
  padding-top: 35px;
}

.profile-info-box p span {
  padding-left: 20px;
}

.profile-avf-indicators-container {
  justify-content: flex-start !important;
  padding-left: 0px !important;
}

.status-approved {
  background: #e7f6ec;
  color: #036b26;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  text-align: left;
}

.status-processing {
  background: #c7d8ff61;
  color: #004dff;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  text-align: left;
}

.status-rejected {
  background: #facecc;
  color: #ec3b35;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  text-align: left;
}

.status-pending {
  background: var(--text-color-100, #0d17171a);
  color: #252e2e;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  text-align: left;
}

.profile-inputs {
  margin-top: 30px;
}

.vd-table-container {
  width: 100%;
  margin-top: 40px;
}

.vd-table-nav {
  height: 40px;
  justify-content: flex-start !important;
  gap: 30px;
}

.vd-table-nav-item-active {
  color: #e9231b !important;
  border-bottom: 3px solid #e9231b !important;
}

.profile-upload-card {
  border: 1px solid rgb(201, 199, 199);
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.profile-upload-card input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 230px;
  cursor: pointer;
}

.profile-uploadver-buttons-cont {
  margin-top: 30px;
}

.profile-uploadver-buttons-cont button {
  padding-left: 45px;
  padding-right: 45px;
}

.profile-preview-doc {
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
}

.profile-preview-doc img {
  width: 230px;
  height: 230px;
  object-fit: contain;
}

.subscription-modal-popup {
  width: 300px !important;
}

.subscription-modal-popup-inner {
  gap: 30px !important;
}

.subscription-modal-popup-inner div {
  justify-content: space-between !important;
  width: 100%;
  align-items: center !important;
}

.t-status-approved {
  background: #e7f6ec;
  color: #036b26;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 130px;
}

.t-status-processing {
  background: #c7d8ff61;
  color: #004dff;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 130px;
}

.t-status-rejected {
  background: #facecc;
  color: #ec3b35;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 130px;
}

.t-status-pending {
  background: var(--text-color-100, #0d17171a);
  color: #252e2e;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 130px;
}

@media screen and (max-width: 500px) {
  .profile-screen-cont {
    padding: 50px 10px 20px 10px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
}
