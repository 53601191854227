.wallet-container {
  padding: 30px;
}

.subcription-screen-container {
  padding: 50px 70px 30px 70px;
}

.wa-stat-card {
  height: 200px;
  width: 200px;
  box-shadow: 1px 4px 12.3px 0px #00000040;
  gap: 10px;
  background-color: white;
  border-radius: 15px;
}

.wa-stat-card-container-icon {
  width: 50px;
  width: 50px;
  border: 1px solid #f59d9a;
  padding: 10px;
  border-radius: 50px;
  margin-bottom: 20px;
}

.wa-stat-left {
  background-image: url(/public/images/wallet/card-bg.png);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 25px;
  gap: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.wa-stat-right,
.wa-stat-left {
  width: 45%;
  height: 250px;
}

.wa-stat-left {
  min-width: 490px;
}

.wa-stat-left-inner-one {
  width: 45%;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}

.wa-stat-left-inner-two {
  width: 45%;
  justify-content: space-between !important;
  align-items: flex-end !important;
}

.w-table-container {
  width: 100%;
  margin-top: 20px;
}

.wallet-table-nav {
  height: 40px;
  justify-content: flex-start !important;
  gap: 30px;
}

.wallet-table-nav-item-active {
  color: #e9231b !important;
  border-bottom: 3px solid #e9231b !important;
}

.wallet-table-sort-dropdown {
  align-items: flex-start !important;
  padding: 15px;
}

.wallet-table-sort-dropdown-radios {
  align-items: flex-start !important;
  gap: 20px;
  padding-left: 5px;
  margin-top: 20px;
}

.w-sub-card-container {
  margin-top: 40px;
}

.w-sub-cards {
  border: 0.8px solid var(--text-color-100, #0d17171a);
  width: 400px;
  height: 240px;
  background: var(--background-color3, #ffffff);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.w-sub-cards-first > :nth-child(2) {
  margin-top: 35px;
}

.w-sub-cards-first > :nth-child(3) {
  margin-top: 20px;
}

.w-sub-cards-first > :nth-child(4) {
  margin-top: 5px;
}

.w-hr {
  width: 100%;
}

.w-sub-cards-second > :nth-child(2) {
  margin-top: 25px;
  border: 0.8px solid var(--text-color-100, #0d17171a);
  padding: 15px;
  border-radius: 8px;
  align-items: flex-start !important;
}

.w-status-active {
  background: #e7f6ec;
  color: #036b26;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  width: 100px;
  text-align: center;
}

.w-status-inactive {
  background: #facecc;
  color: #ec3b35;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  width: 100px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .wallet-intro-container {
    justify-content: center !important;
  }

  .wa-stat-right {
    width: 100%;
    justify-content: space-around !important;
    height: fit-content;
    gap: 15px;
  }

  .wa-stat-left {
    width: 100%;
  }
  .wallet-table-nav {
    display: none;
  }

  .wa-stat-left {
    min-width: 300px;
    padding: 10px;
    gap: 10px;
    background-size: cover;
    background-repeat: repeat !important;
    background-image: url(/public/images/wallet/card-bg.png);
    flex-wrap: wrap;
    justify-content: space-between !important;
    border-radius: 0px;
  }

  .wa-stat-left-inner-one,
  .wa-stat-left-inner-two {
    width: fit-content !important;
    overflow: auto;
  }

  .subcription-screen-container {
    padding: 50px 20px 20px 20px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
  .wallet-intro-container {
    justify-content: center !important;
  }

  .wa-stat-right {
    width: 100%;
    justify-content: space-around !important;
    height: fit-content;
    gap: 15px;
  }

  .wa-stat-left {
    width: 50%;
  }

  .wallet-table-nav {
    gap: 15px;
    height: fit-content !important;
  }
}
