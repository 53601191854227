.subscription-modal-body {
  z-index: 1000 !important;
  min-width: 90% !important;
  min-height: 90vh !important;
  max-width: 90% !important;
  max-height: 90vh !important;
  padding: 0px !important;
}

.subscription-modal-body > :nth-child(1) {
  padding: 20px 20px 0px 0px !important;
  width: 100%;
}

.subscription-modal-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 100px 0px 0px 0px !important;
}

.sub-terms-of-service-modal {
  align-items: flex-start;
  padding: 40px !important;
}

.toggle-switch {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  width: 280px;
  background: rgb(175, 175, 175);
  border-radius: 25px;
}

.toggle-option {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  width: 50%;
  border-style: none;
  outline: none;
  border-radius: 25px;
  transition: background-color 0.3s;
  font-family: "Sora", sans-serif;
  font-size: small;
}

.toggle-option span {
  padding: 3px;
  border-radius: 5px;
}

.toggle-option.active {
  background: #ec3b35;
  color: white;
}

.toggle-option:not(.active) {
  color: white;
  background: rgb(175, 175, 175);
}

.subscription-cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 20px;
}

.subscription-card {
  border: 2px solid #cf5e5a;
  box-shadow: 0px 4px 4px 0px #0000004d;
  box-shadow: 0px 8px 12px 6px #00000026;
  width: 300px;
  height: 430px;
  border-radius: 30px;
  padding: 35px;
  position: relative;
  z-index: 50;
  background: #fbfbfb;
}

.sub-price {
  font-size: 30px !important;
  color: #0d171799;
}

.sub-price span {
  font-size: 15px !important;
  color: #0d171799;
}

.subscription-btn {
  width: 100% !important;
}

.subscription-hr {
  margin-top: 20px;
  width: 100%;
}

.subcription-perks-list-container {
  scrollbar-width: thin;
  height: 160px;
  width: 100%;
  margin-top: 20px;
  justify-content: flex-start;
  overflow: auto;
  color: rgb(38, 38, 38);
}

.sub-perks {
  color: #0d171799 !important;
}

.subscription-cards-container > :first-child {
  margin-right: -10px;
  border-right-width: 0px !important;
}

.subscription-cards-container > :nth-child(2) {
  margin-top: 15px;
  z-index: 100 !important;
}

.subscription-cards-container > :nth-child(3) {
  margin-left: -10px;
  border-left-width: 0px !important;
}

.sub-advert-bg {
  background: #fbfbfb;
  margin-top: 80px;
  width: 100% !important;
}

.subscription-advert {
  width: 80%;
  gap: 30px;
  align-items: center !important;
  justify-content: space-around;
}

.subscription-advert > :nth-child(1) {
  width: 50%;
  justify-content: flex-start;
}

.subscription-advert > :nth-child(1) h3 {
  font-size: 40px !important;
}

.subscription-advert > :nth-child(2) {
}

.subscription-advert > :nth-child(2) img {
  width: 300px;
}

.sub-terms-of-services-actions {
  justify-content: flex-end;
  gap: 40px;
}

.sub-terms-of-services-actions button,
.sub-terms-of-service-btn {
  padding-left: 60px;
  padding-right: 60px;
}

@media screen and (max-width: 500px) {
  .subscription-cards-container {
    flex-wrap: wrap !important;
    gap: 10px;
  }

  .subscription-card {
    min-width: 300px !important;
  }

  .subscription-cards-container > :first-child {
    margin-right: 0px;
    border: 2px solid #cf5e5a;
    border-right-width: 2px !important;
  }

  .subscription-cards-container > :nth-child(2) {
    margin-top: 0px;
    border: 2px solid #cf5e5a !important;
  }

  .subscription-cards-container > :nth-child(3) {
    margin-left: 0px;
    border: 2px solid #cf5e5a;
    border-left-width: 2px !important;
  }

  .subscription-advert {
    padding: 20px;
  }

  .subscription-advert > :nth-child(1) {
    width: 100%;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
  .subscription-cards-container {
    flex-wrap: wrap !important;
    gap: 10px;
  }

  .subscription-card {
    min-width: 300px !important;
  }

  .subscription-cards-container > :first-child {
    margin-right: 0px;
    border: 2px solid #cf5e5a;
    border-right-width: 2px !important;
  }

  .subscription-cards-container > :nth-child(2) {
    margin-top: 0px;
    border: 2px solid #cf5e5a !important;
  }

  .subscription-cards-container > :nth-child(3) {
    margin-left: 0px;
    border: 2px solid #cf5e5a;
    border-left-width: 2px !important;
  }

  .subscription-advert {
    padding: 20px;
  }
}
