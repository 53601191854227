.vf-indicator-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 0px !important;
}

.vf-indicator-cont-left {
  width: fit-content !important;
  height: 50px;
}

.vf-indicator-cont-right {
  width: fit-content !important;
}

.vf-tracker-row {
  gap: 10px !important;
}

.a-vf-indicator {
  width: fit-content;
  flex-wrap: nowrap !important;
  align-items: flex-start;
  height: 40px;
  justify-content: flex-start;
}

.vf-fill-indicator {
  width: 50px;
  height: 10px;
}

.fill-active {
  background-color: #07b939;
}

.fill-inactive {
  background-color: #b0b2c3;
}

.avfindicator-numbertext {
  margin-left: 2px;
  width: 70px;
}
