.settings-screen-cont {
  padding: 50px 70px 30px 70px;
}

.settings-screen-cont h3 {
  margin-bottom: 25px;
}

.settings-section-box {
  width: 100%;
  border: 0.4px solid var(--text-color-400, #0d171766);
  border-radius: 20px;
}

.settings-container-section-header {
  border-bottom: 0.4px solid var(--text-color-400, #0d171766);
  margin: 40px 35px 40px 35px;
  padding-bottom: 17px;
  font-weight: bold;
}

.a-settings-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 35px 0px 35px;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 35px;
}

.settings-bg-color {
  background: var(--text-color-050, #0d17170d);
  margin-top: 50px;
}

.settings-bg-color p,
.settings-bg-color button {
  margin: 25px 35px 25px 35px;
}

@media screen and (max-width: 500px) {
  .settings-screen-cont {
    padding: 50px 10px 20px 10px;
  }

  .a-settings-item-container {
    padding: 0px 10px 0px 10px;
  }

  .settings-container-section-header {
    margin: 40px 10px 40px 10px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
}
