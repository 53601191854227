.App {
  font-family: "Sora", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-height: 100vh;
  background: #fbfbfb;
}

code {
  font-family: "Sora", sans-serif;
}

.font {
  font-family: "Sora", sans-serif;
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    margin-top: -5px;
  }

  to {
    margin-top: 0px;
  }
}

body {
  margin: 0;
  color: #323334;
  font-family: "Sora", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  animation: show 0.6s ease-in-out;
  z-index: 50000;
}

.themeBgColor {
  background: #34204c;
}

.vbig {
  font-size: 22px;
  font-family: "Sora", sans-serif;
  color: #323335;
}

.big {
  font-size: large;
  font-family: "Sora", sans-serif;
  color: #323335;
}

.small {
  font-size: 12.5px !important;
  font-family: "Sora", sans-serif;
  color: #323335;
}

.makeTextALittleBigger {
  font-size: 15px !important;
  font-family: "Sora", sans-serif;
  color: #323335;
}

.biggerText {
  font-size: 15px !important;
  font-family: "Sora", sans-serif;
  color: #323335;
}

.form-check-label {
  font-family: "Sora", sans-serif;
  color: #323335;
}

.xsmall {
  font-size: x-small;
  font-family: "Sora", sans-serif;
  color: #323335;
}

.medium {
  font-size: small;
  font-family: "Sora", sans-serif;
  color: #323335;
}

.themeBtnBlank {
  border-radius: 8px;
  width: fit-content;
  font-family: "Sora", sans-serif;
  outline: none;
  border-style: none;
  font-size: small;
  cursor: pointer;
  background: transparent;
}

.themeBtn {
  background: #e70a02;
  border-radius: 8px;
  width: fit-content;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  font-family: "Sora", sans-serif;
  outline: none;
  border-style: none;
  color: white;
  padding: 10px;
  font-size: small;
  cursor: pointer;
}

.themeBtn:disabled {
  background: rgb(196, 196, 196) !important;
}

.themeBorder {
  border: 1px solid #ec3b35;
}

.csv-link:hover {
  color: #323334;
}

.themeBtn:disabled:hover {
  opacity: 1;
}

.themeBtn:hover {
  opacity: 0.8;
}

.responsiveBtn {
  width: 60%;
  padding: 10px;
}

.MuiTableCell-sizeMedium {
  font-family: "Sora", sans-serif !important;
  font-size: 13px !important;
}

.whitebtn {
  background: white;
  border-radius: 8px;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  font-family: "Sora", sans-serif;
  outline: none;
  border-style: none;
  color: black;
  font-weight: bold;
  padding: 15px;
  font-size: small;
  border: 1px solid rgb(197, 196, 196);
}

.whitebtn:hover {
  background-color: #fa3f3f;
}

.in {
  width: 70%;
  height: 40px;
  border-style: none;
  padding: 10px;
  background-color: white;
  outline: none;
  font-family: "Sora", sans-serif;
  font-size: small;
  opacity: 0.7;
  transition: all 0.4s;
  border: 1px solid #b0b2c3;
}

.in:focus {
  opacity: 1;
  background-color: #f2f1f8;
  border: 1px solid #b0b2c3;
}

.attached-in-cont,
.attached-in-cont-with-icon,
.attached-in-cont-with-icon-no-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 60%;
  margin-top: 20px;
}

.attached-in-cont input {
  width: 70%;
  height: 50px !important;
  border-radius: 10px 0px 0px 10px !important;
  border-right: 0px !important;
}

.attached-in-cont button,
.attached-in-cont-with-icon button {
  width: 30%;
  height: 50px !important;
  border-radius: 0px 10px 10px 0px !important;
}

.attached-in-cont-with-icon input,
.attached-in-cont-with-icon-no-button input {
  border-radius: 0px 0px 0px 0px !important;
  border-left: 0px;
  border-right: 0px !important;
  width: 70%;
  height: 50px !important;
  padding-left: 0px !important;
}

.attached-in-cont-with-icon input:focus,
.attached-in-cont-with-icon-no-button input:focus {
  opacity: 1;
  background-color: white;
  border: 1px solid #b0b2c3;
  border-left: 0px;
}

.attached-in-cont-with-icon div,
.attached-in-cont-with-icon-no-button div {
  border-radius: 10px 0px 0px 10px !important;
  height: 50px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #b0b2c3;
  border-right: 0px;
  background-color: white;
  width: 50px;
}

.attached-in-cont-with-icon-no-button input {
  border-right: 1px solid #b0b2c3 !important;
  border-radius: 0px 10px 10px 0px !important;
  width: 100%;
}

.styled-h3 {
  width: 400px;
  border-top: 2px solid grey;
  border-bottom: 2px solid grey;
}

.removemargin {
  margin: 0px;
}

.whiteBg {
  background-color: white;
}

.themeBg {
  background: #34204c;
}

.blackBg {
  background-color: black;
}

.redBg {
  background-color: #fa3f3f;
}

.themeText {
  background: #f48e8b6b;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bold;
  color: #ec3b35;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.normal-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.normal-flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.small-gap {
  gap: 15px;
}

.no-gap {
  gap: 0px !important;
}

.space-between {
  justify-content: space-between;
}

.no-padding {
  padding: 0px;
}

.width100 {
  width: 100%;
}

.width90 {
  width: 90%;
}

.alignTextLeft {
  text-align: left;
}

.alignTextCenter {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.align-column-left {
  align-items: flex-start;
}

.width-fit-content {
  width: fit-content;
  padding: 10px;
}

.align-row-left {
  justify-content: flex-start;
  gap: 20px;
}

.align-column-center {
  align-items: center;
  gap: 20px;
}

.align-row-center {
  justify-content: center;
}

.yellowText {
  color: #facb0f;
}

.greenText {
  color: #1eff00;
}

.redText {
  color: #e70a02;
}

.boldText {
  font-weight: bold;
  font-family: "Sora", sans-serif;
}

.no-bold {
  font-weight: normal !important;
}

.whiteText {
  color: white;
  font-family: "Sora", sans-serif;
}

.blackText {
  color: black;
  font-family: "Sora", sans-serif;
}

.blueText {
  color: #003cc6;
  font-family: "Sora", sans-serif;
}

.greyText {
  color: grey;
}

.alignTextLeft {
  text-align: left;
}

.cursor {
  cursor: pointer;
  transition: all 0.5;
}

.cursor:hover {
  opacity: 0.7;
}

.flex-gap {
  gap: 30px;
}

.hide {
  display: none;
}

.half-black {
  background: rgba(0, 0, 0, 0.5);
}

.pop-up-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 800;
}

.pop-up-notification {
  width: fit-content;
  height: 45px;
  border-radius: 5px;
  background-color: #e70a02;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 5px;
  gap: 15px;
  z-index: 2100;
  animation: show 0.5s ease-in-out;
  transition: all 0.5s;
  padding: 0px 30px 0px 30px;
  font-family: "Poppins", sans-serif;
}

.a-little-gap {
  gap: 20px;
}

.more-gap {
  gap: 40px;
}
.pop-up-notification p {
  margin-top: 12px;
}

.pop-up-notification img {
  width: 20px;
}

.disabled {
  background: rgb(196, 196, 196) !important;
  cursor: auto !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f48e8b6b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f4827e6b;
}

.screen-container {
  max-height: 84.5vh;
  min-height: 84.5vh;
  padding: 10px 10px 10px 10px;
  overflow-y: auto;
}

.table-small {
  font-size: 12px;
}

.no-hover:hover {
  opacity: 1 !important;
}

.no-cursor {
  cursor: grabbing;
}

@keyframes zoomOut {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.modal-container {
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-body {
  max-width: 50%;
  max-height: 80vh;
  min-height: 80vh;
  background-color: white;
  position: relative;
  z-index: 20000;
  top: 0;
  left: 0;
  animation: zoomOut 0.6s ease-in-out;
  overflow: auto;
  border-radius: 5px;
}

.close-container-modal {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 15px 15px 0px 0px;
  position: absolute;
}

.border-line {
  border: 0.2px solid var(--text-color-400, #0d171766);
}

#mdb-dropdown-custom-dropdown {
  font-family: "Sora", sans-serif;
  width: 45%;
  border-style: none;
  padding: 10px;
  outline: none;
  font-family: "Sora", sans-serif;
  font-size: small;
  margin: 0 !important;
  padding: 0px !important;
}

#mdb-dropdown-custom-in-dropdown {
  border-radius: 5;
  border: 1px solid rgb(201, 199, 199);
  font-family: "Sora", sans-serif;
  background: transparent;
  font-size: small !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;
  padding: 9px 10px 9px 10px !important;
}

#mdb-menu-custom-dropdown {
  width: 100%;
  margin: 0px !important;
  margin-left: -25px !important;
}

input[checkbox],
.table-checkboxes {
  margin-top: 3px;
  cursor: pointer;
}

.modal-overlay {
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content-body {
  box-shadow: 0 5px 10px 0 rgba(92, 92, 100, 0.25);
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2000;
  top: 0;
  left: 0;
  animation: zoomOut 0.6s ease-in-out;
  overflow: auto;
}

.modal-content-body .close-button {
  align-self: flex-end;
  background: transparent;
  border: none;
}

.pac-container {
  z-index: 6051;
}

.table-utilities {
  margin-bottom: 15px;
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.table-utilities-extra {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.table-utilities-icons {
  width: 20%;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

/* Chrome, Safari, Edge, Opera */
.ver-code-input::-webkit-outer-spin-button,
.ver-code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.a-typical-popup {
  width: 300px;
  height: 400px;
  position: absolute;
  z-index: 3000;
  margin-top: 40px;
  margin-left: -130px;
  transition: all 0.5s;
  animation: show 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  background: transparent;
  overflow-y: auto;
}

.form-control:focus ~ .form-notch div:first-child {
  border-color: black !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px white !important;
  clip-path: inset(-8px 0px -8px -8px);
}

.form-control:focus ~ .form-notch div:nth-child(2) {
  border-bottom-color: black !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0), 0 0 8px white !important;
  clip-path: inset(0px 0px -8px 0px);
}

.form-control:focus ~ .form-notch div:last-child {
  border-color: black !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px white !important;
  clip-path: inset(-8px -8px -8px 0px);
}

.form-control:focus ~ .form-label {
  color: black !important;
}

.mdb-dropdown-custom-in {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  padding-top: 9px !important;
}

.mdbIn {
  height: 42px !important;
}

.mdbInFullWidth {
  width: 100% !important;
}

.ant-select-selector {
  border: 1.2px solid rgba(43, 43, 43, 0.321) !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.ant-select-selection-placeholder,
.ant-select-item-option-content {
  color: rgba(43, 43, 43, 0.762) !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
}

.ant-select-selection-search-input {
  color: rgba(43, 43, 43, 0.795) !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.form-control {
  margin-top: 5.5px !important;
  color: rgba(43, 43, 43, 0.795) !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.manual-autocomplete-container {
  width: 100%;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.manual-autocomplete-container-input {
  position: absolute;
  z-index: 20 !important;
  background: transparent !important;
}

.manual-autocomplete-container-icon {
  margin-right: 12px;
  z-index: 30 !important;
  opacity: 0.5;
}

.ant-notification-notice-description {
  font-size: small;
}

/* remove these as its specific to ddsa app */
.popup-common-filter-dropdown {
  width: 200px;
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 10px;
}

.dd-gradient-text {
  background: linear-gradient(90deg, #e9231b 0%, rgba(131, 20, 15, 0.73) 33.55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}

.ant-popconfirm-title {
  font-weight: bold !important;
  font-family: "Poppins", sans-serif !important;
}

.ant-popconfirm-description {
  font-size: small !important;
  font-family: "Poppins", sans-serif !important;
}

.ant-btn {
  font-family: "Poppins", sans-serif !important;
}

.vbig-2 {
  font-size: 20px;
  font-family: "Sora", sans-serif;
  color: #323335;
  margin-top: 20px;
}

.fix-back-button {
  position: fixed;
  margin: -10px 0px 40px 0px;
  z-index: 5000;
  opacity: 0.5;
  transition: all 0.5s;
}

.fix-back-button:hover {
  opacity: 1;
}

.universal-select-button-group-double {
  width: 100%;
  border-radius: 4px;
  border: 1.2px solid rgba(43, 43, 43, 0.321) !important;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.universal-select-button-group-double > :nth-child(1),
.universal-select-button-group-double > :nth-child(2) {
  width: 50%;
  text-align: center;
  font-family: "Sora", sans-serif;
  font-size: small;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
}
.universal-select-button-group-double > :nth-child(1):hover,
.universal-select-button-group-double > :nth-child(2):hover {
  opacity: 0.7;
}

.universal-select-button-group-double > :nth-child(1) {
  border-radius: 4px 0px 0px 4px;
}

.universal-select-button-group-double > :nth-child(2) {
  border-radius: 0px 4px 4px 0px;
}

.universal-select-button-group-double-active {
  background: #e70a02;
  color: white;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

@media screen and (max-width: 500px) {
  .table-utilities {
    gap: 15px;
  }

  .table-utilities-extra {
    width: fit-content !important;
  }

  .table-utilities-icons {
    width: fit-content !important;
  }

  .hide-in-mobile {
    display: none !important;
  }

  .big {
    font-size: larger !important;
  }
}
