.a-stat-card-container {
  height: 200px;
  width: 200px;
  box-shadow: 1px 4px 12.3px 0px #00000040;
  gap: 20px;
  background-color: white;
  border-radius: 15px;
}

.stat-card-container-icon {
  width: 50px;
  width: 50px;
  border: 1px solid #f59d9a;
  padding: 10px;
  border-radius: 50px;
  margin-top: -50px;
  margin-bottom: 20px;
}
