.reset-password-modal-cont {
  margin-top: 40px !important;
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.reset-password-modal-body {
  min-width: 40% !important;
  max-width: 40% !important;
  min-height: 65vh !important;
  max-height: 65vh !important;
}

.reset-password-modal-screens-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.rp-wavy-logo {
  width: 80px;
}

.rp-mdb-login-in {
  width: 80% !important;
  margin-top: 20px;
  margin-top: 5px;
}

.rp-login-btn {
  width: 80%;
  margin-top: 20px;
}

.rp-code-boxes input {
  width: 80%;
  outline: none;
  border-style: none;
  background-color: transparent;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .reset-password-modal-body {
    min-width: 90% !important;
    max-width: 90% !important;
    min-height: 80vh !important;
    max-height: 80vh !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
  .reset-password-modal-body {
    min-width: 90% !important;
    max-width: 90% !important;
    min-height: 80vh !important;
    max-height: 80vh !important;
  }
}
