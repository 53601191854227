.job-list-container {
  padding: 50px 50px 30px 50px;
}

.banks-intro-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  background-image: url("/public/images/dd-banner.png");
  background-size: cover;
}

.banks-intro-container-main {
  width: 95%;
  height: 220px;
  box-shadow: 0px 4px 18.9px 0px #facecc;
  background: var(--background-color3, #ffffff);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
}

.banks-intro-left {
  width: 50%;
  height: 220px;
}

.banks-intro-right {
  width: 45%;
  height: 220px;
}

.banks-stat-container-row {
  margin-top: 40px;
}

.banks-table-container {
  width: 100%;
  padding: 0px 30px 0px 30px;
  margin-top: -20px;
}

.banks-table-sort-dropdown {
  align-items: flex-start !important;
}

.banks-table-search {
  width: 100% !important;
}

.banks-logo {
  width: 40px;
}

.banks-table-dropdown {
  width: 140px !important;
}

.banks-table-dropdown > :nth-child(2) {
  margin-top: 10px;
}

.where-jobs-are {
  gap: 20px;
  margin-top: 40px;
}

.job-details-two,
.job-details-three {
  margin-top: 30px;
}

.job-details-three {
  gap: 20px;
}

.jbd-autocomplete {
  width: 30% !important;
}

.job-details-one {
  width: 100% !important;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  margin-top: 40px;
}

.job-details-card-container {
  box-shadow: 0px 4px 20px 0px #00000040;
  padding: 30px;
  background: white;
  border-radius: 10px;
  overflow: auto;
  scrollbar-width: thin;
}

.jbd-extra-job {
  max-height: 220px;
  width: 35%;
  border-radius: 5px !important;
  box-shadow: 0px 4px 15px 0px #e70a024d;
}

.jbd-extra-job > :nth-child(2) {
  justify-content: flex-start;
  gap: 40px;
}

.jbd-desription {
  width: 60%;
  max-height: 200px;
}

.jbd-category {
  width: 30%;
  max-height: 200px;
}

.jbd-main-assets-cont {
  height: 400px;
  width: 26%;
  padding: 20px;
  min-width: 300px;
  overflow: auto;
}

.jbd-main-assets-cont > :nth-child(1) {
  border-bottom: 1px solid var(--text-color-400, #0d171766);
  padding-bottom: 15px;
}

.jbd-main-assets-cont > :nth-child(2) {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
}

.assets-card {
  flex-wrap: nowrap;
  border-bottom: 1px solid var(--text-color-400, #0d171766);
  cursor: pointer;
  padding-bottom: 15px;
  width: 100% !important;
  transition: all 0.5s;
  overflow: auto;
  scrollbar-width: thin;
}

.assets-card:hover {
  opacity: 0.5;
}

.assets-card > :nth-child(1) {
  width: 70%;
  word-break: break-all;
}

.assets-card > :nth-child(2) {
  width: 30%;
  word-break: break-all;
}

.jbd-subscribeforjob {
  width: 30%;
  max-height: 200px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jbd-subscribeforjob button {
  width: 60%;
}

.mdbdropdown-banks {
  background: white;
}

.jbd-popup-dropdown {
  width: 300px;
}

@media screen and (max-width: 500px) {
  .job-list-container {
    padding: 50px 20px 20px 20px;
  }

  .banks-intro-container-main,
  .banks-intro-container {
    flex-wrap: wrap !important;
    height: fit-content !important;
  }

  .banks-intro-container-main {
    padding: 15px;
    gap: 10px !important;
    justify-content: center;
  }

  .banks-intro-left {
    width: 300px;
    height: fit-content !important;
  }

  .banks-intro-right {
    width: 300px;
    height: fit-content !important;
  }

  .mdbdropdown-banks {
    min-width: 250px !important;
  }

  .jbd-extra-job,
  .jbd-desription,
  .jbd-category,
  .jbd-main-assets-cont {
    min-width: 250px;
  }

  .jbd-extra-job {
    max-height: 230px;
  }

  .jbd-extra-job > :nth-child(2) {
    gap: 10px;
  }

  .job-details-two,
  .job-details-three {
    gap: 10px;
    justify-content: flex-start !important;
  }

  .job-details-one > :nth-child(1) {
    margin-top: 0px !important;
  }

  .jbd-subscribeforjob {
    min-width: 250px;
  }

  .jbd-subscribeforjob p {
    text-align: center;
  }

  .jbd-subscribeforjob-container {
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
  .mdbdropdown-banks {
    min-width: 250px !important;
  }

  .jbd-extra-job,
  .jbd-desription,
  .jbd-category,
  .jbd-main-assets-cont {
    min-width: 300px;
  }

  .jbd-extra-job {
    max-height: 240px;
  }

  .job-details-two,
  .job-details-three {
    gap: 10px;
    justify-content: flex-start !important;
  }

  .jbd-subscribeforjob {
    min-width: 300px;
  }

  .jbd-extra-job > :nth-child(2) {
    gap: 10px;
  }
}
