.job-card-container {
  background: var(--background-color3, #ffffff);
  box-shadow: 0px 4px 20px 0px #00000040;
  width: 260px;
  height: 350px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 25px;
  cursor: pointer;
}

.job-subjobs-list-container {
  scrollbar-width: thin;
  height: 145px;
  width: 100%;
  margin-top: 0px;
  justify-content: flex-start;
  overflow: auto;
  color: rgb(38, 38, 38);
  background: transparent !important;
  scrollbar-track-color: transparent;
}

.job-card-container button {
  margin-top: 20px;
  width: 100%;
  color: #323335;
  border-radius: 15px !important;
}

.job-card-price-range {
  margin-top: 10px;
}

.premium-job-list-card {
  background: conic-gradient(
    from 249.36deg at 90.41% 84.78%,
    #810601 0deg,
    rgba(161, 6, 1, 0.516) 10.8deg,
    #bd0802 147.6deg,
    #810601 360deg
  );
  height: 400px;
}

.premium-job-list-card p,
.premium-job-list-card span {
  color: white !important;
}

.premium-job-list-card button {
  border-style: none;
  background-color: white;
  color: #323335;
}

.premium-job-subjobs-list-container {
  height: 195px;
}

.job-card-sup {
  vertical-align: super !important;
}
