.MuiToolbar-root p {
  margin: 0 !important;
}

.MuiTableContainer-root {
  min-height: fit-content !important;
  max-height: fit-content !important;
}

.MuiTableCell-stickyHeader {
  background: #0d17170d !important;
}

.claims-table-popup {
  width: 300px;
}
