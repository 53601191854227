.bottom-bar-container {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bottom-bar-img {
  object-fit: contain;
  height: 17px;
}
