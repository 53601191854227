.container-button {
  background: white;
  min-height: 120px;
  border-style: none;
  border-bottom: 1px solid #dcdee4;
}

.container-button-active {
  background: #fff7f7;
}

.notification-boxes {
  display: flex;
  flex-direction: column;
}

.notification-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
}
