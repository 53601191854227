.top-bar-container {
  border-bottom: 1px solid #d2d1dc;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px 0px 25px;
}

.top-bar-profile-pic {
  width: 40px;
  border-radius: 40px;
}

.top-bar-status {
  padding: 0px 7px 0px 7px;
  background: #dddddd;
  margin: 0;
  border-radius: 5px;
  color: #0085ff;
}

.header-tools {
  width: fit-content;
  gap: 25px;
}

.top-bar-btn {
  background: #34204c !important;
  width: 150px;
}

.header-dropdown {
  width: 350px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  overflow-y: auto;
}

.notification-header-container-firstp {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content !important;
  gap: 10px;
}

.notification-header-container {
  justify-content: space-between !important;
  padding: 15px;
  box-shadow: 0px 7px 4px 0px #00000005;
  height: 70px;
  width: 100%;
  flex-wrap: nowrap;
}

.notification-count-number {
  background: #ee544e;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.drop-down-button {
  width: 100%;
  background-color: rgb(249, 252, 255);
  padding: 8px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(219, 219, 219);
  text-align: center;
  font-size: small;
  font-family: "Rosario", sans-serif;
  margin: 0px;
  cursor: pointer;
  color: black;
}

.hide {
  display: none;
}

/* @media screen and (max-width: 500px) {
  .media-engine-intro {
    display: none;
  }
} */
