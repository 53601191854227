.support-container {
  padding: 50px 70px 30px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: scroll;
}

.support-container h3 {
  margin-top: 100px;
}

.support-faq-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 50px;
}

.faq-card-container {
  width: 100%;
  background: #f8f8ff;
  padding: 20px;
  box-shadow: 1px 4px 12.3px 0px #aaaaaa40;
  cursor: pointer;
  transition: all 0.5s;
}

.faq-card-container:hover {
  transform: translateY(-5px);
}

.faq-card-basics {
  justify-content: space-between;
  max-height: 80px;
  width: 100%;
}

.faq-card-question {
  width: 88%;
  line-break: auto;
  overflow: auto;
  scrollbar-width: none;
}

@keyframes slideDownFaq {
  from {
    margin-top: 8px;
  }

  to {
    margin-top: 15px;
  }
}

.faq-card-answer {
  width: 100%;
  margin-top: 15px;
  text-justify: newspaper;
  transition: all 0.2s;
  animation: slideDownFaq 0.3s ease-in-out;
}

.faq-card-icon {
  width: 10%;
  justify-content: flex-end !important;
}

.support-section-part {
  margin-top: 80px;
}

.section-support-card-container {
  height: 150px;
  width: 200px;
  box-shadow: 1px 4px 12.3px 0px #00000040;
  background-color: white;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s;
  padding: 10px;
  flex-wrap: nowrap;
  gap: 10px;
}

.section-support-card-container:hover {
  transform: translateY(-10px);
}

.section-support-card-container-icon {
  width: 50px;
  width: 50px;
  border: 1px solid #f59d9a;
  border-radius: 50px;
  padding: 5px;
}

.support-section-content-container,
.support-section-content-container-icon {
  height: 50%;
  align-items: center;
}

.support-section-content-container-icon {
  margin-top: -60px;
}

@media screen and (max-width: 500px) {
  .support-container {
    padding: 50px 20px 20px 20px;
  }

  .support-container h3 {
    margin-top: 80px;
    text-align: center;
  }

  .support-search-box {
    width: 100%;
  }

  .support-section-part {
    gap: 40px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
  .support-section-part {
    gap: 40px;
  }

  .support-search-box {
    width: 100%;
  }
}
