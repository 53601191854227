.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 90vh;
  width: 100%;
  overflow: visible;
}

.login-container-one,
.login-container-two {
  display: flex;
  flex-direction: column;
}

.login-container-one {
  width: 60%;
  overflow: auto;
  height: 100vh;
  justify-content: center;
}

.login-container-two {
  width: 40%;
}

.login-cont {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  overflow-y: auto;
}

.login-subtitle-text {
  width: 80%;
}

.signup-fields-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.login-banner-img {
  object-fit: cover;
  max-width: 100%;
  min-height: 100vh;
}

.wavy-container {
  height: 150px;
  display: flex;
}

.wavy-img {
  position: absolute;
  width: 250px;
  z-index: 5;
}

.wavy-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;
  position: relative;
  z-index: 10;
  width: 100%;
}

.login-in {
  margin-top: 20px;
  width: 55%;
}

.login-btn {
  width: 40%;
  margin-top: 20px;
}

.forgot-remember-login {
  width: 55%;
}

#inlineCheckbox3[type="checkbox"] {
  border: 2px solid #323335;
  border-radius: 3px;
}

#inlineCheckbox3[type="checkbox"]:checked {
  border-color: rgb(26, 25, 25);
  background: rgb(12, 12, 12);
  color: rgb(19, 18, 18);
  content: ".";
}

.code-box-edit-cont {
  width: 55% !important;
}

.code-boxes {
  width: 50px;
  height: 50px;
  border: 1px solid rgb(202, 202, 202);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.code-boxes input {
  width: 70%;
  outline: none;
  border-style: none;
  background-color: transparent;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mdb-login-in {
  width: 55% !important;
  margin-top: 20px;
  margin-top: 5px;
}

.mdb-login-in-flat {
  width: 65% !important;
  margin-top: 10px;
}

.signup-card-type {
  width: 55%;
  height: 50px;
  border: 1px solid rgb(225, 225, 225);
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
}

.signup-card-type:hover {
  background-color: #f48e8b6b;
}

.attached-login-in {
  margin-top: 40px;
}

.login-select-option {
  width: 65%;
}

@media screen and (max-width: 500px) {
  .login-container-two {
    display: none;
  }

  .login-container-one {
    width: 100%;
  }

  .login-cont {
    width: 100%;
  }

  .code-box-edit-cont {
    width: 100% !important;
  }

  .mdb-login-in,
  .mdb-login-in-flat {
    width: 100% !important;
  }

  .forgot-remember-login {
    width: 100%;
  }

  .attached-login-in {
    width: 100% !important;
  }

  .login-select-option {
    width: 100% !important;
  }
}
