.MuiToolbar-root p {
  margin: 0 !important;
}

.MuiTableContainer-root {
  min-height: fit-content !important;
  max-height: fit-content !important;
}

.MuiTableCell-stickyHeader {
  background: #0d17170d !important;
}

.t-status-approved {
  background: #e7f6ec;
  color: #036b26;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 130px;
}

.t-status-processing {
  background: #c7d8ff61;
  color: #004dff;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 130px;
}

.t-status-rejected {
  background: #facecc;
  color: #ec3b35;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 130px;
}

.t-status-pending {
  background: var(--text-color-100, #0d17171a);
  color: #252e2e;
  border-radius: 15px;
  padding: 5px 10px 5px 10px !important;
  font-family: "Sora", sans-serif;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 130px;
}