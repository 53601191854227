.side-nav-container {
  height: 100vh;
  background: #fbfbfb;
  overflow-y: auto;
  width: 20%;
}

.inner-conatiner {
  padding-top: 30px;
  background: #fbfbfb;
  backdrop-filter: blur(120px);
  border-right: 1px solid #d2d1dc;
  height: 100vh;
}

.ddsa-logo-side-nav {
  width: 70px;
}

.side-nav-intro {
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.nav-items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  padding: 40px 20px 0px 20px;
}

.copyright-container {
  margin-top: 150px;
}

.each-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 10px;
  background: none;
  outline: none;
  border-style: none;
}

.each-nav:hover {
  opacity: 0.8;
}

.each-nav-active {
  background: rgba(0, 0, 0, 0.5);
}

.collaps-nav-indicator-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0px;
  height: fit-content;
  margin-bottom: 20px;
  display: none;
}

@media screen and (max-width: 500px) {
  .ddsa-logo-side-nav {
    width: 45px;
  }

  .side-nav-container {
    scrollbar-width: none;
  }
}
