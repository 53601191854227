.jobs-home-container {
  padding: 50px 50px 30px 50px;
}

.my-jobs-empty-cont {
  margin-top: 150px;
}

.my-jobs-empty-cont button {
  width: 20%;
}

.myjobs-table-container {
  width: 100%;
  margin-top: 50px;
}

.jobs-table-utilities {
}

.jobs-table-utilities > :nth-child(1) {
  width: 90% !important;
  padding: 0px !important;
  align-items: center !important;
  gap: 30px;
}

.jobs-table-utilities > :nth-child(2) {
  width: 10% !important;
}

.jobs-table-search-input {
  margin: 0px !important;
  width: 80% !important;
}

.myjob-details-card-container {
  box-shadow: 0px 4px 20px 0px #00000040;
  padding: 30px;
  background: white;
  border-radius: 10px;
  overflow: auto;
  scrollbar-width: thin;
  width: 250px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myjob-details-card-container button {
  width: 65%;
}

.claims-table-nav {
  height: 40px;
  justify-content: flex-start !important;
  gap: 30px;
}

.claims-table-nav-item {
  cursor: pointer;
}

.claims-table-nav-item-active {
  border-bottom: 3px solid rgb(80, 80, 80) !important;
}

.claims-table-nav-item-active-All {
  border-bottom: 3px solid #323335 !important;
}

.claims-table-nav-item-active-Approved {
  border-bottom: 3px solid #07b93980 !important;
}

.claims-table-nav-item-active-Pending {
  border-bottom: 3px solid #f1c21b !important;
}

.claims-table-nav-item-active-Rejected {
  border-bottom: 3px solid #ee544e !important;
}

.claims-table-nav-item-active-Duplicated {
  border-bottom: 3px solid #323335 !important;
}

.sc-inputs-group {
  margin-top: 35px;
  gap: 5px !important;
  flex-wrap: nowrap;
}

.sc-inputs-group > :nth-child(1) {
  background-color: white !important;
}

.sc-inputs-group > :nth-child(2) {
  background-color: white !important;
}

.sc-files-group {
  gap: 15px;
  margin-top: 40px;
  align-items: flex-start !important;
  flex-wrap: wrap;
}

.sc-attach-file-container {
  box-shadow: 0px 4px 18.9px 0px #facecc;
  width: 50%;
  height: 270px !important;
  min-height: 270px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px !important;
  gap: 5px;
  overflow: hidden;
}

.sc-attach-file-container img {
  width: 30px;
}

.sc-attach-file-container-disabled {
  opacity: 0.4;
}

.sc-attach-file-container hr {
  border: 1px solid var(--text-color-300, #0d17174d);
  width: 100%;
}

.sc-attach-file-container > :nth-child(3) {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  border: 1px solid #00000040;
  border-style: dotted;
  width: 90%;
  padding: 10px;
  height: 160px;
  border-radius: 15px;
  position: relative;
}

.sc-attach-file-container > :nth-child(3) > :nth-child(1) {
  width: 40px;
}

.sc-attach-file-container > :nth-child(3) > :nth-child(3) {
  width: 80px;
}

.sc-attach-file-container input {
  opacity: 0;
  position: absolute;
  height: 230px;
  cursor: pointer;
  margin: 0px;
  background: red;
}

.sc-attach-text-container {
  width: 45%;
  height: 290px;
  justify-content: flex-start !important;
}

.sc-attach-text-container > :nth-child(3) {
  margin-top: 20px;
}

.sc-attach-text-container > :nth-child(4) {
  width: 100%;
  padding: 10px;
}

.sc-attach-text-container > :nth-child(5) {
  width: 50%;
  margin-top: 20px;
}

.sc-attach-text-container > :nth-child(1),
.sc-attach-text-container > :nth-child(2) {
  background-color: white;
}

.sc-attach-text-container > :nth-child(2) {
  margin-top: 20px;
}

@media screen and (max-width: 500px) {
  .jobs-home-container {
    padding: 50px 20px 20px 20px;
  }

  .sc-files-group {
    flex-wrap: wrap !important;
  }

  .sc-attach-file-container {
    width: 100% !important;
  }

  .sc-attach-text-container {
    width: 100% !important;
  }
  .sc-attach-file-container > :nth-child(3) {
    flex-wrap: wrap !important;
  }
  .sc-btn-submit {
    width: 100% !important;
  }

  .claims-table-nav {
    display: none !important;
  }
  .submit-new-claim-alert-box {
    margin-top: 30px;
  }

  .my-job-no-btn {
    width: 200px !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 500px) {
  .sc-files-group {
    flex-wrap: wrap !important;
  }

  .sc-attach-file-container {
    width: 100% !important;
  }

  .sc-attach-text-container {
    width: 100% !important;
  }

  .claims-table-nav {
    flex-wrap: wrap !important;
    height: fit-content !important;
    gap: 10px;
  }
}
